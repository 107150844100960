import React from "react";
import { PelemeleWindow, Wall } from "../types/main";

interface Context {
  imageUrl: string;
  picsList: string[];
  windows: PelemeleWindow[];
  wall: Wall | null;
  articleId: string;
  originalName: string;
  minPriceList: Number[];
  lamaList: string[];
  imageWidth: Number;
  imageHeight: Number;
  wList: Number[];
  hList: Number[];
  wSelected: Number;
  hSelected: Number;
  indexSelected: Number;
  matColor: string;
  matStyle: string;
  matShape: string;
  matSize: Number;
  matTop: Number;
  matBottom: Number;
  matLeft: Number;
  matRight: Number;
  mount: string;
  artType: string;
  mouldingName: string;
  selectedStyle: string;
  isCustomized: boolean;
  addImageUrl: (
    url: string,
    articleId: string,
    originalName: string,
    minPriceList: Number[],
    lamaList: string[],
    imageWidth: Number,
    imageHeight: Number,
    wList: Number[],
    hList: Number[]
  ) => void;
  loadImageUrl: (url: string, imageWidth: Number, imageHeight: Number) => void;
  setPicsList: (pics: string[]) => void;
  selectWindows: (windows: PelemeleWindow[]) => void;
  selectWall: (wall: Wall | null) => void;
  selectArticle: (id: string) => void;
  selectSize: (
    w_selected: Number,
    h_selected: Number,
    index_selected: Number
  ) => void;
  selectMatColor: (color_selected: string) => void;
  selectMatStyle: (style_selected: string) => void;
  selectMatSize: (
    mat_top: Number,
    mat_bottom: Number,
    mat_right: Number,
    mat_left: Number
  ) => void;
  selectMatShape: (style_selected: string) => void;
  selectMount: (selected_mount: string) => void;
  selectArtType: (selected_art_type: string) => void;
  selectMouldingName: (selected_moulding_name: string) => void;
  deleteFrame: () => void;
  selectSelectedStyle: (selected_style: string) => void;
  selectImageSize: (
    new_width: number,
    new_height: number,
    new_path: string
  ) => void;
  turnCustomized: () => void;
}

const fContext = React.createContext<Context>({
  imageUrl: "",
  articleId: "",
  originalName: "",
  minPriceList: [],
  picsList: [],
  windows: [],
  wall: null,
  lamaList: [],
  imageWidth: 0,
  imageHeight: 0,
  wList: [],
  hList: [],
  wSelected: 0,
  hSelected: 0,
  indexSelected: 0,
  matColor: "#FEFEFE",
  matStyle: "standard",
  matShape: "standard",
  matSize: 7,
  matTop: 7,
  matBottom: 7,
  matLeft: 7,
  matRight: 7,
  mount: "simple",
  artType: "",
  mouldingName: "",
  selectedStyle: "standard",
  isCustomized: false,
  loadImageUrl: () => {},
  addImageUrl: () => {},
  setPicsList: () => {},
  selectWindows: () => {},
  selectWall: () => {},
  selectArticle: () => {},
  selectSize: () => {},
  selectMatColor: () => {},
  selectMatStyle: () => {},
  selectMatShape: () => {},
  selectMatSize: () => {},
  selectMount: () => {},
  selectArtType: () => {},
  selectMouldingName: () => {},
  deleteFrame: () => {},
  selectSelectedStyle: () => {},
  selectImageSize: () => {},
  turnCustomized: () => {},
});

export default fContext;
